import { ConsoleClientConfig, ConsoleGroup } from './types';

export const loggerPrefix = 'Local Storage: ';

class ConsoleClient {
  private prefix: string;

  private separator: string;

  private enabled: boolean;

  constructor(config?: ConsoleClientConfig) {
    this.prefix = config?.prefix || '';
    this.enabled = config?.enabled || true;
    this.separator = config?.separator || '::';
  }

  getMessage(message: string): string {
    if (this.prefix === '') {
      return message;
    }
    return `${this.prefix} ${this.separator} ${message}`;
  }

  log(message: string, payload?: unknown[], group?: ConsoleGroup): void {
    if (this.enabled) {
      if (!payload) {
        console.log(this.getMessage(message));
        return;
      }

      if (!group) {
        console.log(this.getMessage(message));
        payload.forEach((log: unknown) => {
          console.log(log);
        });
        return;
      }

      if (group === 'expanded') {
        console.group(this.getMessage(message));
      } else if (group === 'collapsed') {
        console.groupCollapsed(this.getMessage(message));
      }
      payload.forEach((log: unknown) => {
        console.log(log);
      });
      console.groupEnd();
    }
  }

  warn(message: string, payload?: unknown[], group?: ConsoleGroup): void {
    if (this.enabled) {
      if (!payload) {
        console.warn(this.getMessage(message));
        return;
      }

      if (!group) {
        console.warn(this.getMessage(message));
        payload.forEach((log: unknown) => {
          console.warn(log);
        });
        return;
      }

      if (group === 'expanded') {
        console.group(this.getMessage(message));
      } else if (group === 'collapsed') {
        console.groupCollapsed(this.getMessage(message));
      }
      payload.forEach((log: unknown) => {
        console.warn(log);
      });
      console.groupEnd();
    }
  }

  error(message: string, payload?: unknown[], group?: ConsoleGroup): void {
    if (this.enabled) {
      if (!payload) {
        console.error(this.getMessage(message));
        return;
      }

      if (!group) {
        console.error(this.getMessage(message));
        payload.forEach((log: unknown) => {
          console.error(log);
        });
        return;
      }

      if (group === 'expanded') {
        console.group(this.getMessage(message));
      } else if (group === 'collapsed') {
        console.groupCollapsed(this.getMessage(message));
      }
      payload.forEach((log: unknown) => {
        console.error(log);
      });
      console.groupEnd();
    }
  }

  setPrefix(prefix: string): void {
    this.prefix = prefix;
  }

  getPrefix(): string {
    return this.prefix;
  }

  setSeparator(separator: string): void {
    this.separator = separator;
  }

  getSeparator(): string {
    return this.separator;
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
  }

  isEnabled(): boolean {
    return this.enabled;
  }
}

export default ConsoleClient;
