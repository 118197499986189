/* eslint-disable  @typescript-eslint/ban-types */
import ConsoleClient from '@phoenix-systems/console-client';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import DragDrop from '@uppy/drag-drop';
import { FileUploadConfig } from './types';

class FileUpload {
  config: FileUploadConfig;

  uppy: any;

  onUploadSucces: Function | null;

  onUploadProgress: Function | null;

  onBeforeFileAdded: Function | null;

  logger: ConsoleClient;

  constructor(config: FileUploadConfig) {
    this.config = config;
    this.uppy = null;
    this.onBeforeFileAdded = config.onBeforeFileAdded || null;
    this.onUploadSucces = config.onUploadSucces || null;
    this.onUploadProgress = config.onUploadProgress || null;

    this.logger = new ConsoleClient({
      prefix: 'FILE UPLOAD CLIENT',
      enabled: this.config.logger
    });

    this.init();
  }

  init(): FileUpload {
    this.uppy = Uppy({
      id: this.config.id,
      autoProceed: false,
      allowMultipleUploads: this.config.multiFile,
      debug: false,
      restrictions: {
        maxFileSize: this.config.maxFileSize || null,
        maxNumberOfFiles: this.config.maxNumberOfFiles || null,
        minNumberOfFiles: this.config.minNumberOfFiles || null,
        allowedFileTypes: this.config.allowedFileTypes || null
      },
      meta: {},
      // locale: en_locale,
      onBeforeFileAdded: (currentFile: any, files: any) => {
        if (this.onBeforeFileAdded) {
          this.onBeforeFileAdded(currentFile, files);
        }
        return currentFile;
      }
    });

    // setup xhr

    this.uppy.use(XHRUpload, {
      endpoint: this.config.api.endpoint,
      method: 'post',
      headers: this.config.api.headers || null,
      formData: true,
      fieldName: this.config.api.formDataFieldName
    });

    // after upload
    this.uppy.on('upload-success', (file: any, response: any) => {
      if (this.onUploadSucces) {
        this.onUploadSucces(file, response);
      }
    });

    // progress
    this.uppy.on('upload-progress', (file: any, progress: any) => {
      if (this.onUploadProgress) {
        this.onUploadProgress(file, progress);
      }
    });

    this.logger.log('initialized', [this], 'collapsed');

    return this;
  }

  initDragDrop(target: any): void {
    this.uppy.use(DragDrop, {
      id: `brose-file_${this.config.id}`,
      target,
      width: '100%',
      height: '100%',
      note: null
      /* locale: {
             strings: {
                dropHereOr: 'Dateien hierher bewegen oder %{browse}',
                browse: 'Dateien durchsuchen'
             }
          } */
    });
  }

  upload(): void {
    this.uppy.upload();
  }
}

export default FileUpload;
